import {useState, useEffect} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
// import ReCAPTCHA from 'react-google-recaptcha';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import './Newsletter.scss';
import loadingImage from '../../assets/images/misc/loading.gif';
import storeIconApple from "../../assets/images/logo/store_itunes.gif";
import storeIconGoogle from "../../assets/images/logo/store_google.gif";

import {
    EmailIcon,
    EmailShareButton,
    FacebookIcon,
    FacebookShareButton,
} from "react-share";

/*
Expected props:
- androidUrl = full url to android store page including https protocol
- appleUrl = full url to app store including protocol
- formLoc (optional) = Set to upper if this is the upper form on the page. Ensures checkbox label on lower form does not affect upper form
- pageMode (optional) = Set to "landing-page" to ensure proper post-submission message for landing page & no redirect on mobile
*/
export default function NewsletterFormContent(props) {

    const apiUrl = "https://pawnewslettersignupserver.azurewebsites.net/sweepstakes";
    
    // const recaptchaKey = process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY;
    const {executeRecaptcha} = useGoogleReCaptcha();
    
    // const [recaptchaToken, setRecaptchaToken] = useState(null);
    const [fieldEmail, setFieldEmail] = useState("");
    const [fieldDay, setFieldDay] = useState("");
    const [fieldMonth, setFieldMonth] = useState("");
    const [fieldYear, setFieldYear] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [isCheckedRules, setIsCheckedRules] = useState(false);
    const [isCheckedTerms, setIsCheckedTerms] = useState(false);
    const [platformSelected, setPlatformSelected] = useState(null);
    const [disableForm, setDisableForm] = useState(false);

    // formSubmitted flag enables us to show the realtime error messages on blank fields only after submission & not before
    // Otherwise, our checks using the isValid() would eval to true even at page load & thus show error msg before user has typed anything
    // (we previously checked if input.length >0 + isValid, but this fails if user submits with empty field as error msg would still be hidden since len == 0)
    // (alt would be to disable submit button if not valid, but checkbox is the prob in that case as you'd never see the checkbox error msg)
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [apiResult, setApiResult] = useState(""); // vals: success, error

    useEffect(() => {
        const cookieVal = Cookies.get('eligibleAge');

        if (cookieVal === "false") {
            setDisableForm(true);
        } else {
            setDisableForm(false);
        }
    }, [])

    // Validates email field
    function isEmailValid() {
        // const validRegex = /^[\w-\.\+]+@([\w-]+\.)+[\w-]{2,4}$/;
        const validRegex = /^[\w-.+]+@([\w-]+\.)+[\w-]{2,4}$/;
        if (!fieldEmail.match(validRegex)) {
            return false;
        } else {
            return true;
        }
    }

    // Checks if a given string input represents a valid integer
    function isValidInteger(textVal){
        if (textVal === "") {
            return false; // Number("") evals to 0, so we test "" string separately from integer check
        } else {
            let numVal = Number(textVal);
            if (!isNaN(numVal) && Number.isInteger(numVal)) {
                return true;
            } else {
                return false;
            }
        }
    }

    // Checks if the DOB field states represent a real date starting from 1/1/1800
    function isDobValid() {
        if (isValidInteger(fieldDay) && isValidInteger(fieldMonth) && isValidInteger(fieldYear)) {
            let dayNum = Number(fieldDay);
            let monthNum = Number(fieldMonth);
            let yearNum = Number(fieldYear);
            if (dayNum >= 1 && dayNum <= 31 && monthNum >= 1 && monthNum <= 12 && yearNum >= 1800 && yearNum <= 2024) {
                return true;
            } else {
                return false; // number is not a valid date
            }
        } else {
            return false; // not a valid integer
        }
    }

    // Returns True if the current states for D, M, Y are equivalent to 18 years old or older. 
    // Does NOT do any validation on the states as it's expected those have already been checked as valid integers before this fxn is called
    function isOver18() {
        let dayNum = Number(fieldDay);
        let monthNum = Number(fieldMonth);
        let yearNum = Number(fieldYear);

        let dob = new Date(yearNum, monthNum - 1, dayNum); // month is zerobased for Date
        let today = new Date();
        let dateFor18 = new Date(today.getFullYear() - 18, today.getMonth(), today.getDay());

        return dob <= dateFor18; 
    }

    // Handles form submission
    const handleSubmit = async (event) => {
        event.preventDefault();
        setApiResult(""); // clear out any prev msgs, eg network error msg
        setFormSubmitted(true);

        // if (isChecked && isEmailValid() && isFirstNameValid() && isLastNameValid()) {
        // if (recaptchaToken && isCheckedRules && platformSelected && isDobValid() && isEmailValid()) {
        if (executeRecaptcha && isCheckedRules && platformSelected && isDobValid() && isEmailValid()) {

            const token = await executeRecaptcha('submit');
            // setRecaptchaToken(token);
            // console.log("here is the v3 token: ", token);

            // Fire custom event that will trigger G.Ads conversion tracking
            window.dataLayer.push({
                "event":"sweeps-form-submitted"
            });

            // Fire the meta conversion pixel upon submitting form with no entry errors
            // Use window.fbq not just fbq as <head>'s script embed adds the fxn to window obj
            // window.fbq('track', 'PageView');
            // window.fbq('track', 'CompleteRegistration', { value: 1,});
            window.fbq('track', 'CompleteRegistration');

            // Check if the DOB is 18+. If not, set invalid cookie and DO NOT SUBMIT
            if (!isOver18()) {
                //set cookie and exit
                Cookies.set('eligibleAge', 'false', { expires: 7 });
                setDisableForm(true);
                return;
            }

            // display the loading icon in the form
            setIsLoading(true);

            // GET utm_source param value
            // 1st get the query params
            const searchParams = new URLSearchParams(window.location.search);
                // Could also use const [searchParams, setSearchParams] = useSearchParams() but all remaining code is same so why load another hook?
            // 2nd, convert all param names (not values) to lowercase since find value fxn searchparams.get() is case sensitive
            const newParams = new URLSearchParams();
            for (const [name, value] of searchParams) { 
                newParams.append(name.toLowerCase(), value);
            }
            const utmSourceValue = newParams.get('utm_source');
            
            // Create the data object that will post to endpoint
            const formData = { 
                email: fieldEmail,
                optedInToNewsletters: isChecked,
                optInType: isChecked ? 'Single' : null,
                emailType: 'Html',
                dob: fieldDay+"-"+fieldMonth+"-"+fieldYear,
                platformSelected: platformSelected,
                REFERRER: utmSourceValue,
                SIGNUP_PAGE: window.location.href,
                TERMS_BOX: isCheckedTerms,
                recaptchaToken: token,
            };
            // console.log("here is the formdata to be submitted: ", formData);

            // Now make the axios call
            axios.post(`${apiUrl}`, formData)
            .then(result => {  
                // SUCCESSFUL API SUBMISSION
                setIsLoading(false);
                setApiResult("success");
                // The success msg and redirect will happen in the return JSX automatically, triggered by these state changes


                
                /******************************************* */
                // INSERT STUFF TO FIRE GOOGLE ANALYTICS EVENT HERE
                // dataLayer only works with GTM loaded;
                /******************************************* */
                // window.dataLayer.push({'event': 'lower-form-submitted'});


            }).catch(err => {
                // ERROR WITH API SUBMISSION
                // This will fire if network returns a non-2xx status
                // Generally if this fires, can assume that it's due to auth or network error, not form validation issue
                // console.error("Error:", error);

                setIsLoading(false);
                if (err.response && err.response.data && err.response.data.code && err.response.data.code === "INVALID_LOCATION") { // User is not in US or Can, so not eligible to enter sweepstakes
                    // console.log("region error from server:", err.response.data);
                    setApiResult("error-region");
                } else { // Other error occurred during submission
                    // console.log("Error posting item to api: ", err);
                    setApiResult("error");
                }
                
                
            });
            return;       
        }
    }

    // Updates form field states as you type in them
    function handleChange(event) {
        if (event.target.name === "emailField") {
            setFieldEmail(event.target.value);
        } else if (event.target.name === "optinCheckbox") {
            setIsChecked(!isChecked);
        } else if (event.target.name === "dayInForm") {
            setFieldDay(event.target.value);
        } else if (event.target.name === "monthInForm") {
            setFieldMonth(event.target.value);
        } else if (event.target.name === "yearInForm") {
            setFieldYear(event.target.value);
        } else if (event.target.name === "platformRadioButtons") {
            setPlatformSelected(event.target.value);
        } else if (event.target.name === "rulesCheckbox") {
            setIsCheckedRules(!isCheckedRules);
        } else if (event.target.name === "termsCheckbox") {
            setIsCheckedTerms(!isCheckedTerms);
        }
    }

    return (
        <div>The Yelp for Help Sweepstakes has ended and winners will be contacted shortly. Please keep an eye on your inbox!</div>
    )
    
    return (
        <>        
            {/* <!-- PCH pixel offer conversion --> */}
            {(apiResult !== "") 
            ? <>
            <div className="form__show-block form__show-on-mobile"><img src="https://liquidpch.go2cloud.org/aff_l?offer_id=12253" width="1" height="1" alt="" /></div>
            <div className="form__show-block form__show-on-desktop"><img src="https://liquidpch.go2cloud.org/aff_l?offer_id=12252" width="1" height="1" alt="" /></div>
            </>
            : null
            }
            {/* <!-- Success message --> */}
            <div className={`form__success ${(apiResult === "success") ? "" : "form__success--hide"}`}>
                <p className={`form__success-copy`}>Entry Successful!<br />Choose a store to download now:</p>
                <div className="form__success-icons-box">
                        <a href={props.appleUrl} className="form__success-link" target="_blank" rel="noreferrer"><img className="form__success-icon" src={storeIconApple} alt="Pre-Order app on Apple App store" /></a>
                        <a href={props.androidUrl} className="form__success-link" target="_blank" rel="noreferrer"><img className="form__success-icon" src={storeIconGoogle} alt="Pre-Order app on Google Play store" /></a>
                </div>
                <div className="form__success-buttons-box">Share on:<br />
                    <EmailShareButton
                    url={window.location.href}
                    subject="Enter the PAW Patrol Academy Yelp For Help Sweepstakes"
                    body="Enter the PAW Patrol Academy Yelp For Help Sweepstakes for a chance to win $1000 and other prizes"
                    >
                        <EmailIcon size={64} round className="form__success-share-button" />
                    </EmailShareButton>
                    <FacebookShareButton
                    url={window.location.href}
                    title="Enter the PAW Patrol Academy Yelp For Help Sweepstakes #yelpforhelpsweepstakes"
                    >
                        <FacebookIcon size={64} round className="form__success-share-button" />
                    </FacebookShareButton>
                    
                </div>  
            </div>
            {/* <!-- Error with submission message  --> */}
            <div className={`form__error ${(apiResult === "error") ? "" : "form__error--hide"}`}>
                <p className="form__error-copy">Sorry, there was an error<br />submitting your request.<br />Please try again later or email <a href="mailto:support@pawpatrolacademy.com">support@pawpatrolacademy.com</a> for help!</p>
            </div>
            {/* <!-- Error with submission message specifically for regionaal issue (must be in US or Canada) --> */}
            <div className={`form__error ${(apiResult === "error-region") ? "" : "form__error--hide"}`}>
                <p className="form__error-copy">Sorry, you must be in the USA or Canada to enter!</p>
            </div>
            {/* <!-- Eligibility message (user previously submitted below 18-years-old form) --> */}
            <div className={`form__error ${(disableForm) ? "" : "form__error--hide"}`}>
                <p className="form__error-copy">Sorry, you do not meet the eligibility requirements to enter the sweepstakes.</p>
                {/* <p className="form__error-copy">Sorry, you must be<br />18 or over to enter!<br /><br />If you made a mistake in your submission, then please clear your browser's cookies and refresh the page to try again.</p> */}
            </div>
            <div className={`${(disableForm) ? "form__error--hide" : ""}`}>
                {/* <!-- Inline form --> */}
                <form id="form-inline__bottom" className={`form-inline ${(apiResult === "success")? "form__success--hide" : ""}`} onSubmit={handleSubmit} noValidate>
                    <p className={`form-inline__warning ${(!isEmailValid() && (fieldEmail.length > 0 || formSubmitted)) ? "" : "form-inline__warning-hide"}`}>
                        Please add a valid email address</p>
                    <input type="email" id="emailField" name="emailField" className="form-inline__input" placeholder="Email Address" value={fieldEmail} onChange={handleChange} />
                    <div className="form-inline__checkbox-container">
                        <label htmlFor="optinCheckboxTop" className="form-inline__checkbox-label">
                            <input type="checkbox" id="optinCheckboxTop" name="optinCheckbox" className="form-inline__checkbox" checked={isChecked} onChange={handleChange} />
                            <span className="form-inline__checkbox-label-copy">Yes! I want to receive PAW Patrol Academy™ promotional emails from Spin&nbsp;Master</span>
                        </label>
                    </div>
                    <p className={`form-inline__warning ${(!isDobValid() && formSubmitted) ? "" : "form-inline__warning-hide"}`}>Please enter a valid date of birth in MM/DD/YYYY format</p>
                    <div className="form-inline__radiobox-container">
                        <p className="form-inline__checkbox-label">Date of Birth:</p>
                        <div className="form-inline__dob-fields-box">
                            <input className="form-inline__input" value={fieldMonth} onChange={handleChange} type="text" name="monthInForm" placeholder="MM" maxLength="2" pattern="\d{2}" />
                            <input className="form-inline__input" value={fieldDay} onChange={handleChange} type="text" name="dayInForm" placeholder="DD" maxLength="2" pattern="\d{2}" />
                            <input className="form-inline__input" value={fieldYear} onChange={handleChange} type="text" name="yearInForm" placeholder="YYYY" maxLength="4" pattern="\d{4}" />
                        </div>
                    </div>
                    <p className={`form-inline__warning ${((!isCheckedRules || !platformSelected) && formSubmitted) ? "" : "form-inline__warning-hide"}`}>Please agree to the rules & select a platform</p>
                    <div className="form-inline__radiobox-container">
                        <p className="form-inline__checkbox-label">Choose your platform:</p>
                        <label className="form-inline__radiobox-label"><input
                        type="radio"
                        name="platformRadioButtons"
                        value="iOS"
                        checked={platformSelected === 'iOS'}
                        onChange={handleChange}
                        />&nbsp;iOS</label>
                        <label className="form-inline__radiobox-label"><input
                        type="radio"
                        name="platformRadioButtons"
                        value="Android"
                        checked={platformSelected === 'Android'}
                        onChange={handleChange}
                        />&nbsp;Android</label>
                    </div>
                    <div className="form-inline__checkbox-container">
                        <label htmlFor="rulesCheckboxTop" className="form-inline__checkbox-label">
                            <input type="checkbox" id="rulesCheckboxTop" name="rulesCheckbox" className="form-inline__checkbox" checked={isCheckedRules} onChange={handleChange} />
                            <span className="form-inline__checkbox-label-copy">I have read and agree to the <Link to="/sweepstakes/rules" target="_blank" rel="noopener noreferrer">Official Rules</Link></span>
                        </label>
                    </div>
                    <div className="form-inline__checkbox-container form-inline__checkbox-container--terms">
                        <label htmlFor="termsCheckboxTop" className="form-inline__checkbox-label">
                            <input type="checkbox" id="termsCheckboxTop" name="termsCheckbox" className="form-inline__checkbox" checked={isCheckedTerms} onChange={handleChange} />
                            <span className="form-inline__checkbox-label-copy">I have read and agree to the <Link to="/sweepstakes/terms" target="_blank" rel="noopener noreferrer">Terms &amp; Conditions</Link></span>
                        </label>
                    </div>
                    <button type="submit" className="form-inline__button" disabled={disableForm}>
                        <img className={`form-inline__button-loading ${(isLoading) ? "" : "form-inline__hide-misc"}`} src={loadingImage} loading="lazy" alt="loading progress" />
                        {(!isLoading) ? (<span className="form-inline__button-copy">Submit</span>) : ""}
                    </button>
                </form>
            </div>
        </>
                
    )
}