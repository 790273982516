import {useState, useEffect} from 'react';
import {Link, useLocation} from 'react-router-dom';

import PPALogo from '../../assets/images/logo/logo-paw-patrol-academy-smaller_trans2.webp';
import hamburgerButton from '../../assets/images/misc/hamburger.gif'; 
import closeButton from '../../assets/images/misc/close_icon.png'; 
import socialFB from "../../assets/images/social/icon-facebook.svg";
import socialIG from "../../assets/images/social/icon-instagram.svg";
import socialYT from "../../assets/images/social/icon-youtube.svg";
import pawIcon from '../../assets/images/social/icon-paw.gif';
import googleAward from '../../assets/images/misc/google-award.webp';
import kidscreenAward from '../../assets/images/misc/kidscreenawards_nominee.webp';
import goodplayguideAward from '../../assets/images/misc/goodplayguide.webp';
// import spotlightBackSmall from '../../assets/images/misc/spotlight-small4.png';
import spotlightBackSmall from '../../assets/images/misc/spotlight-small.webp';

import {handleDownloadClick} from '../../utils/gotostore.js';
import './Header.scss';



export default function Header({pageType, activitiesRef, newsletterRef, appleUrl, androidUrl}) {

    const [hamburgerState, setHamburgerState] = useState(true);  
    

    // closes/opens the mobile nav menu
    const handleNavMenuClick = () => {
        // When used by nav menu links that go to a section, ensures the expanded mobile menu is closed
        setHamburgerState(!hamburgerState);
    }

    // scrolls user to desired section
    // trackingType - Optional. Fires GA event depending on value. Accepted: exploreClick, newsletterClick
    const handleAnchorClick = (sectionReference, trackingType) => {
        
        // for event tracking since this is not a real link that gets tracked automatically
        if (trackingType === "exploreClick") {
            window.dataLayer.push({
                "event":"navbar-explore-click",
            })
        } else if (trackingType === "newsletterClick") {
            window.dataLayer.push({
                "event":"preheader-bar-newsletter-click",
            })
        }

        // handleNavMenuClick(); // can't use this now that it's in a useCallback as it becomes a dependency. So just copy code here.
        if (!hamburgerState) { 
            setHamburgerState(!hamburgerState);
        }

        let blockPosition = "start";
        // if ((window.innerWidth < 1024) && (currLocation.hash === "#explore")) {
        if (window.innerWidth < 1024) {
            blockPosition = "center"; // #newsletter link works better with blockPosition='start' before u scroll down the page but not subsequent jumps once you have scrolled
        }
        sectionReference.current.scrollIntoView({
            behavior: "smooth",
            block: blockPosition,
            inline: "nearest",
        });
    }

    const currLocation = useLocation();
    
    useEffect(()=> {
        // Used when user clicks on Explore from a NON-homepage page
        if (currLocation.hash === "#explore") {
            handleAnchorClick(activitiesRef);
        } else if (currLocation.hash === "#newsletter") {
            handleAnchorClick(newsletterRef);
        } else {
            window.scrollTo(0,0);
        }
        // eslint-disable-next-line
    }, [currLocation.hash])  
    // Using esline-disable as technically should have handleAnchorClick & activitiesRef in here but then handAncCl needs to be in useCallback which then doesn't fxn here
    // Further useCallback requires all of its params to also be in that dependency array, but can't do that cuz it's a param that coudl be anything. 
    // So would require heavy refactoring for minimal benefit


    return (
        <>
        
        <section className="preheader">
            <p className="preheader__body">
                {/* <Link to="https://rebrand.ly/pawpatrolacademy" target="_blank" rel="noreferrer noopener" className="preheader__link">Watch PAW Patrol: The Mighty Movie now streaming on Paramount+</Link> */}
                {/* <Link to={`/${window.location.search}#newsletter`} className="preheader__link">Join our mailing list for deals, news, and updates!</Link> */}
                <Link to={`/${window.location.search}#newsletter`}  onClick={() => handleAnchorClick(newsletterRef, "newsletterClick")} className="preheader__link">Join our mailing list for deals, news, and updates!</Link>
                {/* <Link to={`/sweepstakes/${window.location.search}`} className="preheader__link">Parents! We are giving away $2500 in total prizes. Learn more.</Link> */}
                {/* {
                    (pageType === "Home") 
                        ? <button className="navbar__nav-link navbar__nav-link--btn" onClick={() => handleAnchorClick(activitiesRef)}>Explore</button>
                        : <Link className="navbar__nav-link" to={`/${window.location.search}#explore`}>Explore</Link>
                } */}
            </p>
        </section>          

        <header className='header'>
            
            <nav className={`navbar ${(pageType === "news") ? "navbar--compact-header" : ""}`}>
                {
                (pageType === "Home") 
                ? <Link to={`/${currLocation.search}`} className="navbar__logo-link" onClick={() => (window.scrollTo(0,0))}><img className="navbar__logo-image" src={PPALogo} alt="Paw Patrol Academy logo" /></Link>
                : <Link to={`/${currLocation.search}`} className="navbar__logo-link"><img className="navbar__logo-image" src={PPALogo} alt="Paw Patrol Academy logo" /></Link>
                }
                    
                
                {/* <button className="navbar__download-button-outer" onClick={handleDownloadClick}>Download</button> */}
                <img className="navbar__hamburger-button" src={`${(hamburgerState) ? hamburgerButton : closeButton}`} alt="hamburger menu" onClick={handleNavMenuClick} />
                <div className={`navbar__content-box ${(hamburgerState) ? "navbar__content-box--hide" : ""}`}>
                    <div className="navbar__content-item">
                        <Link className="navbar__nav-link" to={`/news${window.location.search}`} onClick={() => handleNavMenuClick()}>Updates</Link>
                    </div>
                    <div className="navbar__content-item">
                        {
                            (pageType === "Home") 
                            ? <button className="navbar__nav-link navbar__nav-link--btn" onClick={() => handleAnchorClick(activitiesRef,"exploreClick")}>Explore</button>
                            : <Link className="navbar__nav-link" to={`/${window.location.search}#explore`} onClick={() => handleAnchorClick(activitiesRef,"exploreClick")}>Explore</Link>
                        }
                    </div>
                    <div className="navbar__content-item">
                        <Link className="navbar__nav-link" to={`/printables${window.location.search}`} onClick={() => handleNavMenuClick()}>Printables</Link>
                    </div>
                    <div className="navbar__content-item navbar__content-social-box">
                        <Link to="https://www.facebook.com/profile.php?id=100090709899105" target="_blank" className="navbar__content-social-link">
                            <img src={socialFB} className="navbar__content-social-icon navbar__content-social-icon--fb" alt="facebook icon" />
                        </Link>
                        <Link to="https://www.instagram.com/pawpatrolacademy/" target="_blank" className="navbar__content-social-link">
                            <img src={socialIG} className="navbar__content-social-icon navbar__content-social-icon--ig" alt="instagram icon" />
                        </Link>
                        <Link to="https://www.youtube.com/channel/UC1-svEIqpWoIG6vw8MLdqsQ" target="_blank" className="navbar__content-social-link">
                            <img src={socialYT} className="navbar__content-social-icon navbar__content-social-icon--yt" alt="youtube icon" />
                        </Link>
                        <Link to="https://www.pawpatrol.com?utm_source=referral&utm_medium=pawpatrolacademy.com" target="_blank" className="navbar__content-social-link">
                            <img src={pawIcon} className="navbar__content-social-icon navbar__content-social-icon--paw" alt="paw" />
                        </Link>
                    </div>   
                    <div className="navbar__content-item navbar__content-button-box">
                        <button className="download-button navbar__content-button" onClick={() => handleDownloadClick("navbar", appleUrl, androidUrl)}>Download Now</button>
                    </div>   
                </div>
            </nav>
            { (pageType === "Home") ?
                // (<div className="header__award header__award-alt">
                //     <img className="header__award-spotlight" src={spotlightBackSmall} alt="Read more about PAW Patrol's Google Play Best of 2023 award" />
                //     <Link to="/news/post/86"><img className="header__award-image" src={googleAward} alt="Google Best of 2023 Award for PAW Patrol Academy App" /></Link>    
                // </div>) : ""
                (
                    <div className="header__award-group header__award-group-alt">
                    <div className="header__award header__award-alt">
                        <Link to="https://www.goodplayguide.com/reviews/paw-patrol-academy/" target="_blank"><img className="header__award-image header__award-image-secondary" src={goodplayguideAward} alt="Good Play Guide Recommended Award for PAW Patrol Academy App" /></Link>
                    </div>
                    <div className="header__award header__award-alt">
                        <img className="header__award-spotlight" src={spotlightBackSmall} alt="Read more about PAW Patrol's Google Play Best of 2023 award" />
                        <Link to="/news/post/86"><img className="header__award-image header__award-image--with-spotlight" src={googleAward} alt="Google Best of 2023 Award for PAW Patrol Academy App" /></Link>
                    </div>
                    <div className="header__award header__award-alt">
                        <Link to="/news/post/134"><img className="header__award-image header__award-image-secondary" src={kidscreenAward} alt="KidScreen Award Nominee PAW Patrol Academy App" /></Link>
                    </div>                    
                </div>
                ) : ""
            }   
            <div className={`header__image ${(pageType !== "Home") ? "header__image--non-home" : "" } ${(pageType === "news") ? "header__image--compact-header" : ""}`}>
                 
                <div className="header__content-box">
                { (pageType === "Home") ? 
                    (<>
                    <div className="header__award-group">
                        <div className="header__award header__award-primary">
                            <Link to={`/news/post/144/${window.location.search}`}><img className="header__award-image header__award-image-secondary" src={goodplayguideAward} alt="Good Play Guide Recommended Award for PAW Patrol Academy App" /></Link>
                        </div>
                        <div className="header__award header__award-primary">
                            <img className="header__award-spotlight" src={spotlightBackSmall} alt="Read more about PAW Patrol's Google Play Best of 2023 award" />
                            <Link to={`/news/post/86/${window.location.search}`}><img className="header__award-image header__award-image--with-spotlight" src={googleAward} alt="Google Best of 2023 Award for PAW Patrol Academy App" /></Link>
                        </div>
                        <div className="header__award header__award-primary">
                            <Link to={`/news/post/134/${window.location.search}`}><img className="header__award-image header__award-image-secondary" src={kidscreenAward} alt="KidScreen Award Nominee PAW Patrol Academy App" /></Link>
                        </div>                    
                    </div>
                    
                    <h1 className="header__title header__title--desktop">Award-Winning Preschool Learning App</h1>
                    <h1 className="header__title header__title--mobile">Best Preschool Learning&nbsp;App</h1>
                    {/* <h1 className="header__title">It's Learning Unleashed</h1> */}
                    {/* <h2 className="header__subtitle">Get your free 7-day trial&nbsp;started&nbsp;today</h2> */}
                    <h2 className="header__subtitle">Start your free trial<span className="header__subtitle-non-mobile">&nbsp;today</span></h2>
                    <button className="download-button" onClick={() => handleDownloadClick("splash", appleUrl, androidUrl)}>Download Now</button>
                    <p className="header__disclaimer">For iOS and Android devices</p>
                    </>
                    ) 
                : "" }
                { (pageType === "FAQ") ? 
                (<h1 className="header__title"><span className="header__subtitle">Frequently Asked</span><br />Questions</h1>) 
                    : "" }
                </div>
            </div>
            
        </header>
        </>
    )
}